
import React from "react"
import styled from 'styled-components'

import Layout from "../layouts/layout"
import ImgBackground from "../components/imgBackground"

const ParallaxContainer = styled.div`
    perspective: 1px;

    height: 100vh;

    overflow-x: hidden;
    overflow-y: scroll;
`

const ParallaxImgBackground = styled(ImgBackground)`
    height: 100vh;

    transform: translateZ(-1px) scale(2);
`

const ParallaxContent = styled.div`
    transform: translateZ(0px);
`

export default ({ children }) => (
    <ParallaxContainer>
        <ParallaxImgBackground/>    
        <ParallaxContent>
            <Layout>
                { children }
            </Layout>
        </ParallaxContent>
    </ParallaxContainer>
) 
