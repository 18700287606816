import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ParallaxLayout from "../layouts/parallaxLayout"
import Gallery from "../components/gallery"
import Review from "../components/review"

export default ({ data }) => (    
  <ParallaxLayout>
    <FullPageDiv>
      <CenteredDiv>
        <CenteredImg 
          fluid={data.logo.childImageSharp.fluid}
          critical={true}
        />
      </CenteredDiv>
    </FullPageDiv>
    <ColoredCenteredDiv>
      <h1>
        Every performance is totally unique
      </h1>
      <p>
        This Is Your Musical is the hilarious improvised musical show where a cast of professional actors, accompanied by a fantastic band of talented musicians, create a brand-new, smash-hit musical right before your eyes.
      </p> 
      <FullWidthDiv>
        <Gallery/>
      </FullWidthDiv>
      <p>Every musical we create is inspired by YOUR stories.</p>
      <Divider/>
      <Review reviewer="Visit Bristol">We were bowled over by their quick wit.</Review>
      <Review reviewer="Audience Feedback">It was excellent, I was full-on belly laughing!</Review>
      <Review reviewer="Audience Feedback" noSeparator>It was absolutely fantastic! So funny, so clever and had all of us so engaged – awesome stuff!</Review>
      <Divider/>
    </ColoredCenteredDiv>
  </ParallaxLayout>
)

const Divider = styled.span`
  width: 80%;
  height: 5px;
  margin: 2em 0;

  border: solid 1px #2a3a4a;
  border-radius: 120px;
  background-color: #2a3a4a;
`

const FullPageDiv = styled.div`
  height: 100vh;
`

const CenteredImg = styled(Img)`
  width: 85%;
  margin: 0 auto;
`

const CenteredDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p, h1 {
    max-width: 35.5rem;
    margin: .5em;
    padding-top: 1.0em;

    text-align: center;
  }

  > p {
    max-width: 25.5rem;
    padding-bottom: 1.5em;
  }

  > p + span {
    margin-top: 0.5em;
  }
`

const ColoredCenteredDiv = styled(CenteredDiv)`
  height: auto;

  background-color: #1a2a3a;

  padding: 0 0.5em 2.5em 0.5em;

  .test {
    width: 50%;
  }
`

const FullWidthDiv = styled.div`
  width: calc(100% + 0.5em * 2);
`

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "TIYM-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`