import React from "react"
import styled from "styled-components"

export default class Review extends React.Component {
    render()
    {
        return (
            <ReviewContainer>
                <h3>“{this.props.children}”</h3>
                <p>{ this.props.reviewer }</p>  
                { !this.props.noSeparator && <Dot/> }
            </ReviewContainer>
        )
    }
}

const Dot = styled.span`
    display: inline-block;
    margin-top: 1.5em;
    height: 10px;
    width: 10px;

    background-color: #2a3a4a;
    border-radius: 50%;
`

const ReviewContainer = styled.div`

    margin: 1em 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    h3 {
        max-width: 35.5rem;
        margin: 0;
    }

    p {
        margin: 0;
        padding: 0.5em 0;
    }
`