import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { CSSTransitionGroup } from 'react-transition-group'

const Gallery = () => (
    <StaticQuery
        query={
            graphql`
                query {
                    galleryFiles: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
                        edges {
                            node {
                                childImageSharp {
                                    fluid(maxWidth: 1920, maxHeight: 1080) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            `
        }

        render={
            data => (
                <SlideShow>
                    {
                        data.galleryFiles.edges.map((edge, i) => 
                            <GalleryImg key={i} fluid={edge.node.childImageSharp.fluid}/>
                        )
                    }
                </SlideShow>
            )
        }
    />
)

const GalleryContainer = styled.div`
  width: 100%;
  height: auto;

  position: relative;

  overflow: hidden;
`

const GalleryImg = styled(Img)`
    width: 100%;
    height: 100%;
`

const Slide = styled.div`
    width: 100%;
    height: 100%;

    &.slide-leave-active, &.slide-leave {

        position: absolute;
        top: 0;
        z-index: 5;

        opacity: 0.0;
        transition: opacity 3s ease-in-out;
    }
`
class SlideShow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSlideIdx: 0,
            prevSlideIdx: this.props.children.length - 1,
        }
    }

    componentDidMount() {
        this.startCarousel();
    }

    componentWillUnmount() {
        clearInterval(this.carouselInterval);
    }

    startCarousel = () => {
        this.carouselInterval = setInterval(() => {
            this.transitionSlides();
        }, 7000);
    };

    transitionSlides = () => {

        var newSlideIdx = this.state.currentSlideIdx + 1

        if (newSlideIdx >= this.props.children.length) {
            newSlideIdx = 0
        }

        this.setState({
            currentSlideIdx: newSlideIdx,
            prevSlideIdx: this.state.currentSlideIdx,
        })
    }

    render() {
        return (
            <GalleryContainer>
                <CSSTransitionGroup
                transitionName="slide"
                transitionEnter={false}
                transitionLeaveTimeout={3000}>
                    <Slide key={this.state.currentSlideIdx}>
                        {this.props.children[this.state.currentSlideIdx]}
                    </Slide>
                </CSSTransitionGroup>
            </GalleryContainer>
        )
    }
}

export default Gallery